import React from 'react';

import Typography from 'components/uiLibrary/Typography';
import Card from 'components/Globals/Card';
import Image from 'components/uiLibrary/Image';

import classes from './MediaCard.module.scss';

const MediaCard = ({ title, subTitle, imageProps, imageTrackingData, linkProps, trackerProps, className }) => (
  <Card trackerProps={trackerProps} linkProps={linkProps} className={className}>
    <Image {...imageProps} className={classes.image} trackingData={imageTrackingData} />
    {(title || subTitle) && (
      <div className={classes.mediaCard__details}>
        <Typography variant="p" weight="medium" className={classes.title}>
          {title}
        </Typography>
        <Typography color="secondary" className={classes.subTitle} size={12}>
          {subTitle}
        </Typography>
      </div>
    )}
  </Card>
);

export default MediaCard;
